import { DownOutlined } from "@ant-design/icons";
import { Popover, Space, Select, Table } from "antd";
import "antd/lib/pagination/style/index.css";
import { ColumnsType } from "antd/lib/table";
import { SortOrder, SorterResult } from "antd/lib/table/interface";
import "antd/lib/table/style/index.css";
import { PlanType } from "backend/utils/plan";
import useGeoExploratory from "hooks/useGeoExploratory";
import { useUserData } from "hooks/useUser";
import { stateMap } from "lib/constants";
import { ResponsiveContext } from "lib/context";
import { getNameForCode } from "lib/helpers/exploratoryHelpers";
import { getNameForScoreCode } from "lib/helpers/scoreHelpers";
import { metroMap } from "lib/options/metroMap";
import { capitalize } from "lodash";
import pick from "lodash/pick";
import { MapboxGeoJSONFeature } from "mapbox-gl";
import { usePostHog } from "posthog-js/react";
import {
  forwardRef,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { CSVLink } from "react-csv";
import { Geo } from "types/MapContext";
import { CurrentDataPoints } from "types/cube";
import { IFilter, StateOption } from "types/options";
import {
  getMapDisplayValue,
  sanitizeGeoName,
  slugify,
} from "../../lib/helpers";
import { Button } from "../Button";
import { Text } from "../Text";
import { PlanModalContext } from "../provider/PlanModalProvider";
import { DataPointDropdown } from "./DataPointDropdown";

interface Props {
  tableView: boolean;
  layer: string;
  mapData: CurrentDataPoints[];
  geo: Geo;
  filteredStates: StateOption[];
  exploratory: string;
  selectedFilters: IFilter[];
}

const DataTableMemo = forwardRef(
  (
    {
      tableView,
      layer,
      mapData,
      geo,
      filteredStates,
      exploratory,
      selectedFilters,
    }: Props,
    ref: any,
  ) => {
    const map = ref?.current?.getMap();

    const user = useUserData();
    const posthog = usePostHog();
    const { geoSpecificFilterValues, groupedExploratoriesWithScores } =
      useGeoExploratory(geo);
    const dataFields = geoSpecificFilterValues();

    const { isTabletOrMobile } = useContext(ResponsiveContext);
    const { setShowPlanModal, setPlanPopupTrigger } =
      useContext(PlanModalContext);

    const [page, setPage] = useState(1);
    const [data, setData] = useState<any>([]);
    const [columns, setColumns] = useState<ColumnsType<any>>([]);
    const [columnsArray, setColumnsArray] = useState<string[]>([]);
    const [sortOrder, setSortOrder] = useState<any>(null);
    const [pageSize, setPageSize] = useState(10);

    const isDataPointNotAvailable = (dataPoint) => {
      if (user?.isPremiumOrBasic) {
        return false;
      } else {
        return groupedExploratoriesWithScores
          .map((groupedExploratory) => groupedExploratory.options)
          .flat()
          .find((exploratory) => exploratory.value === dataPoint)?.premium;
      }
    };

    const defaultColumns = [
      exploratory,
      ...(isTabletOrMobile
        ? []
        : [
            "home_value_growth_rate",
            "over_under_valued_percentage",
            "home_value_to_earnings_ratio",
            "population",
            "share_of_listings_with_price_cut",
          ]),
    ];

    const renderName = (feature: MapboxGeoJSONFeature) => {
      switch (geo) {
        case Geo.ZIP:
          return sanitizeGeoName(feature.id);
        case Geo.COUNTY:
          return `${feature.properties?.BASENAME}, ${stateMap.find(
            (state) => state.stateCode == feature.state.STATE,
          )?.stateAbbr}`;
        case Geo.METRO: {
          return `${metroMap.find(
            (metro) =>
              metro?.["CBSA Code"] === parseInt(feature.properties?.GEOID),
          )?.CBSA}`;
        }
        case Geo.STATE:
          return feature.properties?.NAME;
        default:
          return feature.properties?.NAME;
      }
    };

    const state = useMemo(() => {
      if (!tableView) {
        setPage(1);
      }
      if (!map) {
        return [];
      }

      let features: MapboxGeoJSONFeature[] = [];

      if (map.getLayer(layer)) {
        features = map.queryRenderedFeatures(undefined, { layers: [layer] });
      }

      const uniqueFeatures = features?.filter(
        (feature, index, self) =>
          index === self.findIndex((t) => t.id === feature.id),
      );

      return uniqueFeatures
        ?.filter((feature) => feature?.state.isFiltered)
        ?.map((feature, index) => {
          const filteredState = pick(feature.state, ["name", ...dataFields]);

          const updatedFilteredState = {
            index: index + 1,
            name: renderName(feature),
            [exploratory]: null,
          };

          const setUpdatedFilteredState = (property) => {
            const propertyValue = filteredState[property];

            updatedFilteredState[property] =
              !propertyValue ||
              isNaN(propertyValue as number) ||
              !isFinite(propertyValue as number)
                ? "-"
                : getMapDisplayValue({
                    exploratory: property,
                    value: propertyValue,
                  });
          };

          for (const property in filteredState) {
            if (property === exploratory) {
              setUpdatedFilteredState(property);
            } else if (property !== "name" && property !== "isFiltered") {
              setUpdatedFilteredState(property);
            }
          }

          return {
            ...updatedFilteredState,
            key: updatedFilteredState.name
              ? slugify(updatedFilteredState.name)
              : `${index}`,
          };
        });
    }, [
      tableView,
      map,
      layer,
      mapData,
      filteredStates,
      exploratory,
      selectedFilters,
    ]);

    useEffect(() => {
      if (state.length > 0) {
        const availableDataColumns = Object.keys(state[0]);
        setColumnsArray(
          availableDataColumns.filter((key) => {
            if (key !== "name" && key !== "index" && key !== "key") {
              return defaultColumns.includes(key);
            }
            return key !== "key";
          }),
        );
      } else {
        setColumnsArray([]);
      }
    }, [state]);

    useEffect(() => {
      if (state.length > 0) {
        setData(
          state.map((data) => {
            return pick(data, [...columnsArray, "key"]);
          }),
        );
      } else {
        setData([]);
      }
    }, [columnsArray]);

    useEffect(() => {
      if (state.length > 0) {
        setColumns(
          columnsArray.map((key, index) => ({
            width: key === "index" ? 50 : key === "name" ? 75 : 95,
            ellipsis: true,
            sorter: (a, b, direction) => {
              if (key === "name" || key === "Cleaned Name") {
                return a[key].localeCompare(b[key]);
              }

              if (typeof a[key] === "string" && a[key] && b[key]) {
                const getFormatted = (obj) =>
                  obj[key].includes("$")
                    ? obj[key].replace(/[^a-zA-Z0-9 ]/g, "")
                    : obj[key].includes(",")
                    ? obj[key].replace(/,/g, "")
                    : obj[key];
                const formattedA = getFormatted(a);
                const formattedB = getFormatted(b);
                if (formattedA === "-" && direction === "ascend") {
                  return 1;
                }
                if (formattedA === "-" && direction === "descend") {
                  return -1;
                }
                if (formattedB === "-" && direction === "ascend") {
                  return -1;
                }
                if (formattedB === "-" && direction === "descend") {
                  return 1;
                }

                const numA = formattedA.includes("%")
                  ? parseFloat(formattedA)
                  : Number(formattedA);
                const numB = formattedB.includes("%")
                  ? parseFloat(formattedB)
                  : Number(formattedB);

                return numA - numB;
              }
              return a[key] - b[key];
            },
            sortDirections:
              key === "index" ? [null] : (["descend", "ascend"] as SortOrder[]),
            defaultSortOrder:
              key === exploratory ? ("descend" as SortOrder) : null,
            fixed: key === "index" || key === "name" ? "left" : false,
            title: () => {
              if (key === "index" || key === "name") {
                return key === "name" ? "Name" : "RK";
              }
              return (
                <Popover
                  key={index}
                  trigger={["click"]}
                  placement="bottom"
                  content={() => (
                    <div onClick={(e) => e.stopPropagation()}>
                      <DataPointDropdown
                        currentDataPoint={key}
                        geo={geo}
                        currentIndex={index}
                        setColumnsArray={setColumnsArray}
                        dropDownOptions={groupedExploratoriesWithScores}
                      />
                    </div>
                  )}
                >
                  <a
                    onClick={(e) => e.stopPropagation()}
                    css={() => ({
                      color: "white",
                      textWrap: "wrap",
                      lineHeight: "15px",
                      ":hover": { color: "white" },
                    })}
                  >
                    <Space>
                      <div
                        css={() => ({
                          display: "-webkit-box",
                          textOverflow: "ellipsis",
                          wordBreak: "break-word",
                          overflow: "hidden",
                          WebkitLineClamp: "2",
                          WebkitBoxOrient: "vertical",
                        })}
                      >
                        {getNameForCode(key) ||
                          getNameForScoreCode(key) ||
                          (key === "name"
                            ? "Name"
                            : key === "index"
                            ? "Index"
                            : key)}
                      </div>
                      <DownOutlined />
                    </Space>
                  </a>
                </Popover>
              );
            },
            dataIndex: key,
            key,
            render: (value, rec, index) => {
              if (key === "index") {
                return (page > 1 ? (page - 1) * pageSize : 0) + index + 1;
              }
              if (isDataPointNotAvailable(key) && key !== "name") {
                return (
                  <div
                    css={() => ({
                      filter: "blur(5px)",
                    })}
                    onClick={() => {
                      setPlanPopupTrigger(
                        "Click disabled column data Table view",
                      );
                      setShowPlanModal(true);
                    }}
                  >
                    N/A
                  </div>
                );
              }
              return value;
            },
          })),
        );
      }
    }, [columnsArray, page, sortOrder]);

    return (
      <div
        css={() => ({
          height: "100%",
          width: "100%",
          marginTop: 20,
          position: "relative",
        })}
      >
        <div
          css={() => ({ position: "absolute", top: -33, left: 15, zIndex: 1 })}
        >
          <Text fontSize={isTabletOrMobile ? "large" : "heading2"}>
            Reports ({capitalize(geo)})
          </Text>
        </div>
        <div
          css={() => ({ position: "absolute", top: -33, right: 70, zIndex: 1 })}
        >
          {!user.isPremiumOrBasic ? (
            <Button
              onClick={() => {
                setPlanPopupTrigger("Download Report Table view");
                setShowPlanModal(true);
              }}
              variant="alternate"
              css={() => ({ height: 37, paddingTop: 0, paddingBottom: 0 })}
            >
              Download Report
            </Button>
          ) : (
            <CSVLink
              filename={`reventure-${geo}-data.csv`}
              data={data.map((item) => {
                const newItem = {};
                Object.keys(item).forEach((key) => {
                  newItem[getNameForCode(key) || getNameForScoreCode(key)] =
                    item[key];
                });
                return newItem;
              })}
            >
              <Button
                onClick={() => {
                  posthog.capture("Download Report Table View");
                }}
                css={() => ({ height: 37, paddingTop: 0, paddingBottom: 0 })}
                variant="alternate"
              >
                Download Report
              </Button>
            </CSVLink>
          )}
        </div>
        <Table
          key={JSON.stringify(columns)}
          columns={columns}
          dataSource={data}
          pagination={{
            showSizeChanger: !isTabletOrMobile,
            showTotal: (total, range) => {
              if (!isTabletOrMobile) {
                return (
                  <span>
                    Showing {range[1]} of <b>{total}</b>
                  </span>
                );
              }
            },
            position: ["bottomCenter"],
            locale: {
              items_per_page: "",
              jump_to: !isTabletOrMobile ? "Rows per " : "",
            },
            current: page,
            onChange: (current) => {
              setPage(current);
            },
            showQuickJumper: !isTabletOrMobile,
          }}
          onChange={(pagination, _filters, sorter) => {
            setSortOrder((sorter as SorterResult<any>).order || null);
            setPageSize(pagination.pageSize || 10);
          }}
          bordered
          scroll={{ x: 300 }}
          css={(theme) => ({
            ".ant-pagination-total-text": {
              position: "absolute",
              left: 16,
              color: theme.colors.lightSlateGray,
            },
            ".ant-pagination-options": {
              position: "absolute",
              right: 16,
              textTransform: "revert",
            },
            "ant-pagination-options:after": {
              content: '"abc"',
            },
            ".ant-select-arrow": {
              color: theme.colors.lightSlateGray,
            },
            ".ant-pagination-options-size-changer > .ant-select-selector": {
              borderRadius: theme.radii.smaller,
            },
            ".ant-pagination-options input": { display: "none" },
            ".ant-pagination-item, .ant-pagination-item-link": {
              border: "none",
              a: { color: theme.colors.lightSlateGray },
            },
            ".ant-pagination-item-active": {
              background: theme.colors.primary,
              borderRadius: theme.radii.small,
              a: { color: "white" },
            },
            ".ant-table-row": {
              textAlign: "center",
              height: "50px !important",
            },
            ".ant-table-tbody > tr > td": {
              padding: "0px 16px !important",
              textAlign: "center",
              fontWeight: theme.fontWeights.heading,
            },
            "table > thead > tr > th": {
              borderRight: `1px solid #D6D6D6 !important`,
              textAlign: "center",
            },
            ".ant-table-column-title": {
              // minWidth: 'fit-content',
            },
            ".ant-table-container > .ant-table-content > table > tbody > tr > td":
              {
                borderColor: theme.colors.inputBorder,
              },
            "thead[class*='ant-table-thead'] th": {
              backgroundColor: "#000 !important",
              color: "#fff !important",
              fontSize: "12px !important",
              paddingTop: "0px !important",
              paddingBottom: "0px !important",
              textOverflow: "ellipsis",
              height: "57px !important",
              wordBreak: "keep-all",
            },
            "tbody tr:nth-of-type(even) .ant-table-cell": {
              backgroundColor: "#f3f3f3 !important",
            },
            ".ant-table-thead>tr>th": {
              padding: isTabletOrMobile ? "0px !important" : "16px",
            },
          })}
        />
      </div>
    );
  },
);
export const DataTable = memo(DataTableMemo);

DataTableMemo.displayName = "DataTable";
