/** @jsxImportSource @emotion/react */

// Dependencies
import * as Unicons from "@iconscout/react-unicons";
import {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import BaseMap, {
  Layer,
  LayerProps,
  MapRef,
  NavigationControl,
  Popup,
  Source,
  ViewState,
} from "react-map-gl";
import { toast } from "react-toastify";

// Components
import DataDrawer from "../Drawer";
import { Flex } from "../Flex";
import HoverPin from "../HoverPin";
import { mapboxStyleMap } from "../MapStyleSelector";
import { Text } from "../Text";
import { TimeSeriesExploratory } from "../TimeSeries/TimeSeriesExploratory";
import { DataTable } from "../table/DataTable";

// Utilities
import { stateCoordinates, zipCountyMap } from "lib/constants";
import { MapContext } from "lib/context";
import { getMapDisplayValue, sanitizeGeoName } from "lib/helpers";
import { geoSettingsMap } from "lib/mapSettings";
import { stateList } from "lib/options/stateList";
import { Offset } from "mapbox-gl";
import { getDataByLatLng } from "services/geocodeService";

// Styles
import { posthog } from "posthog-js";

import { useTheme } from "@emotion/react";
import { MapLoader } from "components/MapLoader";
import { PlanModalContext } from "components/provider/PlanModalProvider";
import useFilters from "hooks/useFilters";
import useGeoSearch from "hooks/useGeoSearch";
import useMapData from "hooks/useMapData";
import { useOutsideClick } from "hooks/useOutsideClick";
import useProgressQuery from "hooks/useProgressQuery";
import { getNameForCode } from "lib/helpers/exploratoryHelpers";
import { findFillLayer } from "lib/helpers/mapHelpers";
import { getNameForScoreCode } from "lib/helpers/scoreHelpers";
import { useMounted } from "lib/hooks";
import { countyList } from "lib/options/countyList";
import { countyMetroMap } from "lib/options/countyMetroMap";
import { metroList } from "lib/options/metroList";
import { zipMetro } from "lib/zipMetro";
import { debounce, isEmpty, keys } from "lodash";
import "mapbox-gl/dist/mapbox-gl.css";
import { Geo } from "types/MapContext";
import { LayerFillIds, SourceMap } from "types/Mapbox";
import { Datapoints } from "types/cube";
import MapData from "./MapData";
import useGeoExploratory from "hooks/useGeoExploratory";

export default function Map({
  setIsMapLoaded,
}: {
  setIsMapLoaded: (v: boolean) => void;
}) {
  // Context
  const {
    mapStyle,
    tableView,
    graphView,
    graphData,
    showToolTip,
    isTabletOrMobile,
    setTableView,
    setShowGraphView,
  } = useContext(MapContext);

  const {
    geo,
    lat,
    long,
    zoom,
    searchId,
    metroFilter,
    filteredStates,
    graphContext,
    setGraphContext,
    searchZipsViaState,
    setLat,
    setLong,
    setSearchId,
    setSearchValue,
    setSuggestions,
    setSearchedGeo,
    setSelectedFeature,
    setFilteredStates,
    setFilteredMetros,
    setFilteredCounties,
    setShowFocusTooltip,
    setSearchZipsViaState,
  } = useGeoSearch();

  const { exploratoryOptions, groupedExploratoriesWithScores } =
    useGeoExploratory(geo);

  const { progressQuery, setProgressQuery } = useProgressQuery();
  const { exploratory, handleExploratoryChange, selectedFilters } =
    useFilters();
  const { setShowPlanModal, setPlanPopupTrigger } =
    useContext(PlanModalContext);

  // Hooks
  const mounted = useMounted();
  const theme = useTheme();
  const { currentData, isCurrentLoading } = useMapData();
  // Refs
  const ref = useRef<MapRef | null>(null);
  const map = ref.current?.getMap();
  const nodeRef = useRef<HTMLDivElement | null>(null);

  // Local state
  const [layers, setLayers] = useState<Record<string, LayerProps[]>>({});
  const [cursor, setCursor] = useState<string>("auto");
  const [popupInfo, setPopupInfo] = useState(null);
  const [hoverTooltip, setHoverTooltip] = useState<any>(null);
  const [hoveredId, setHoveredId] = useState<any>(null);
  const [blockedArea, setBlockedArea] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  useOutsideClick(nodeRef, () => setHoverTooltip(null));

  const handleCenter: MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    ref?.current?.easeTo({
      center: [-95, 39],
      zoom: geoSettingsMap[geo].minZoom,
      duration: 750,
    });
  };

  const handleClick = useCallback(
    ({ lat, lng, state, properties, featureList = [] as any[] }) => {
      const abbreviatedName = sanitizeGeoName(
        geo === Geo.ZIP ? state.GEOID : properties.FULLNAME,
      );
      const abbreviatedShortName = sanitizeGeoName(
        geo === Geo.ZIP ? state.GEOID : properties.BASENAME,
      );

      setHoverTooltip(null);
      setLat(lat);
      setLong(lng);
      setSearchId({ id: `${lat}${lng}`, lat: lat, long: lng });
      setSearchValue("");
      setSearchedGeo("");
      setSuggestions([]);
      setSelectedFeature(null);
      const value = properties[exploratory] || state[exploratory];

      if (geo === Geo.ZIP && !Object.keys(state).length) {
        return;
      }
      const zip = geo === Geo.ZIP ? abbreviatedName : "";
      const county = zip
        ? countyList.find(
            (item) => zipCountyMap[zip]?.padStart(5, "0") === item.value,
          )?.label
        : geo === Geo.COUNTY
        ? properties.FULLNAME
        : "";
      const metroFromCounty = county
        ? countyMetroMap.find(
            (item) =>
              `${item.countycountyequivalent}, ${item.statename}` === county,
          )
        : null;

      const metroFromGeoId =
        !county && geo === Geo.METRO
          ? metroList.find((item) => `${item.value}` === `${properties.GEOID}`)
          : null;

      const metro = county
        ? metroFromCounty?.cbsatitle
        : geo === Geo.METRO
        ? metroFromGeoId?.label
        : "";
      const cbsaCode = metroFromCounty?.cbsacode || metroFromGeoId?.value;
      const statename = metro
        ? countyMetroMap.find((item) => `${item.cbsacode}` === `${cbsaCode}`)
            ?.statename
        : "";
      const attributes = {
        ...(zip ? { zip } : {}),
        ...(metro ? { metro } : {}),
        ...(county ? { county } : {}),
        state: statename,
      };

      posthog.capture("Clicking the map", attributes);
      if (!isTabletOrMobile) {
        setShowGraphView(true);
      }

      setGraphContext({
        ...state,
        ...properties,
        STUSPS: abbreviatedShortName,
        NAME: abbreviatedName,
        featureList: featureList,
        exploratory,
        exploratoryDisplayValue: getMapDisplayValue({ exploratory, value }),
        geo,

        zoom,
        lat,
        lng,

        handleLearnMore: setShowGraphView,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [exploratory, geo, zoom],
  );
  const handleMouseEnter = useCallback(() => setCursor("pointer"), []);
  const handleMouseLeave = useCallback(() => setCursor("auto"), []);
  const CustomToastWithGraphCta = useCallback(
    () => (
      <div
        css={() => ({
          textAlign: "center",
        })}
      >
        <Flex
          inline
          justify="space-between"
          align="center"
          onClick={() => {
            setShowGraphView(true);
            setHoverTooltip(null);
          }}
          css={(theme) => ({
            backgroundColor: theme.colors.red,
            borderRadius: "9999px",
            margin: "4px 0px",
            padding: "10px 0px",
            cursor: "pointer",
            width: "100%",
          })}
        >
          <Flex
            align="center"
            justify="center"
            css={(theme) => ({
              color: theme.colors.white,
              textAlign: "center",
              margin: "0 auto",
            })}
          >
            <Unicons.UilChartLine
              css={(theme) => ({
                marginRight: theme.margin.small,
                cursor: "pointer",
                height: theme.fontSizes.medium,
                width: theme.fontSizes.medium,
              })}
            />
            <Text medium>View Graph</Text>
          </Flex>
        </Flex>
      </div>
    ),
    [],
  );

  const handleMapClick = async (e: mapboxgl.MapLayerMouseEvent) => {
    const { lng, lat } = e.lngLat;

    if (!!e?.features?.length) {
      if (geo === Geo.ZIP && searchZipsViaState === "All States") {
        const metroFeature = findFillLayer(e.features, LayerFillIds[Geo.METRO]);

        if (!!metroFeature) {
          const skipUpdate = currentData.some(
            (zip) => metroFeature?.id === zipMetro[zip.geo_code],
          );
          if (!skipUpdate) {
            const updatedFilteredMetro = metroFilter.find((metro) => {
              return metro.value == metroFeature.id;
            });

            if (updatedFilteredMetro) {
              setSearchZipsViaState("All States");
              setLat(lat);
              setLong(lng);
              setFilteredStates([]);
              setFilteredMetros([updatedFilteredMetro]);
              setFilteredCounties([]);
              setSearchedGeo("");
            }
          }
        }
      }
      const feature = findFillLayer(e.features, LayerFillIds[geo]);

      if (feature?.state?.isFiltered) {
        setProgressQuery({
          ...progressQuery,
          query: feature.state.name,
          lnglat: JSON.stringify(e.lngLat),
          graphView: true,
          GEOID: feature.properties?.GEOID,
          zoom,
        });

        if (geo === Geo.COUNTY && isTabletOrMobile) {
          const stateCode = (feature.id as string).slice(0, 2);
          const matchedState = stateList.find(
            (state) => state.value === stateCode,
          );
          if (matchedState) {
            setFilteredStates([matchedState]);
          }
        }

        if (feature?.properties) {
          const contextParams = {
            lng,
            lat,
            state: feature.state,
            properties: feature.properties,
          };
          handleClick(contextParams);

          if (isTabletOrMobile && !popupInfo) {
            try {
              const value =
                feature.properties[exploratory] || feature.state[exploratory];
              setHoveredId((prev) => {
                if (prev?.id !== feature.id) {
                  return { id: feature.id, prevId: prev?.id };
                }
                return prev;
              });

              const abbreviatedName = sanitizeGeoName(
                geo === Geo.ZIP
                  ? feature.properties.BASENAME
                  : feature.properties.FULLNAME,
              );
              const abbreviatedShortName = sanitizeGeoName(
                geo === Geo.ZIP
                  ? feature.properties.BASENAME
                  : feature.properties.FULLNAME,
              );

              setHoverTooltip({
                exploratory,
                STUSPS: abbreviatedShortName,
                NAME: abbreviatedName,
                exploratoryDisplayValue: getMapDisplayValue({
                  exploratory,
                  value,
                }),
                label:
                  getNameForCode(exploratory) ||
                  getNameForScoreCode(exploratory),
                properties: feature.properties,
                state: feature.state,
                lng,
                lat,
                geo,
                detailedClick: handleClick,
                selectedFilters,
                geoContext: currentGeoContext,
              });
            } catch (e) {
              console.error("Error", e);
            }
          } else {
            setHoverTooltip(null);
          }
        }
      } else if (feature?.state?.isBlocked) {
        setPlanPopupTrigger("Click disabled map layer");
        setShowPlanModal(true);
      }
    } else {
      if (geo === Geo.COUNTY && isTabletOrMobile) {
        try {
          const data = await getDataByLatLng(lat, lng);
          if (data?.features?.length) {
            const stateData = data.features.find((featureData) =>
              featureData.id.includes("region"),
            );
            const matchedState = stateList.find(
              (stateInfo) => stateInfo.label === stateData?.text,
            );
            if (matchedState) {
              setFilteredStates([matchedState]);
            }
          }
        } catch (e) {
          console.error("Error", e);
        }
      }
    }
  };
  // Effects
  useEffect(() => {
    if (!showToolTip) {
      setPopupInfo(null);
      setHoverTooltip(null);
    }
  }, [showToolTip]);

  //hide tooltip when the user changes the geo/select another data point on mobile devices
  useEffect(() => {
    if (isTabletOrMobile) {
      setHoverTooltip(null);
    }
  }, [geo, exploratory]);

  useEffect(() => {
    if (hoverTooltip && isTabletOrMobile) {
      toast(CustomToastWithGraphCta, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
        toastId: "toast-click-id",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverTooltip]);
  useEffect(() => {
    if (!isEmpty(searchId) && !!map) {
      const props: mapboxgl.FlyToOptions = {
        duration: searchId.duration || 1200,
        curve: 0.1,
      };

      if (searchId.long && searchId.lat)
        props.center = [searchId.long, searchId.lat];
      if (searchId.zoom) props.zoom = searchId.zoom;

      map.flyTo(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, map]);
  useEffect(() => {
    // setRefreshing(true);
  }, [geo]);

  // Variables
  const initialState: Partial<ViewState> = {
    latitude: lat,
    longitude: long,
    zoom: zoom || 4,
  };
  const currentGeoContext = undefined;

  const updateMapViewState = debounce((event) => {
    const { zoom, latitude, longitude } = event.viewState;
    setProgressQuery((prevState) => ({
      ...prevState,
      zoom: zoom,
      lnglat: JSON.stringify({ lng: longitude, lat: latitude }),
    }));
  }, 500);

  if (!mounted) {
    return <></>;
  }

  return (
    <div
      ref={(divRef) => {
        if (nodeRef) {
          nodeRef.current = divRef;
        }
      }}
      css={{ flexGrow: 1 }}
    >
      {isCurrentLoading && <MapLoader />}
      <div css={{ position: "absolute" }} id="map-tour-tooltip" />
      <BaseMap
        onLoad={(e) => {
          const mapTooltip = document.getElementById("map-tour-tooltip");
          const pos = e.target.project([long, lat]);
          if (mapTooltip) {
            mapTooltip.style.left = pos.x + "px";
            mapTooltip.style.top = pos.y + "px";
            setIsMapLoaded(true);
          }
        }}
        cursor={cursor}
        initialViewState={initialState}
        interactiveLayerIds={
          geo === Geo.ZIP
            ? [LayerFillIds[geo], LayerFillIds[Geo.METRO]]
            : [LayerFillIds[geo]]
        }
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_API_KEY}
        mapStyle={mapboxStyleMap[mapStyle]}
        maxZoom={geoSettingsMap[geo].maxZoom}
        minZoom={geoSettingsMap[geo].minZoom}
        onClick={handleMapClick}
        onMouseDown={() => {
          if (isTabletOrMobile) {
            setHoverTooltip(null);
          }
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={async (e) => {
          const feature = e?.features?.find(
            (feature) => feature.layer.id === LayerFillIds[geo],
          );
          const { lng, lat } = e.lngLat;

          if (
            feature?.state &&
            feature?.properties &&
            !popupInfo &&
            feature.state.isFiltered === true &&
            !feature.state.isBlocked
          ) {
            try {
              const value =
                feature.properties[exploratory] || feature.state[exploratory];
              setHoveredId((prev) => {
                if (prev?.id !== feature.id) {
                  return { id: feature.id, prevId: prev?.id };
                }
                return prev;
              });

              if (!showToolTip) {
                return;
              }

              const abbreviatedName = sanitizeGeoName(
                geo === Geo.ZIP
                  ? feature.properties.BASENAME
                  : feature.properties.FULLNAME,
              );
              const abbreviatedShortName = sanitizeGeoName(
                geo === Geo.ZIP
                  ? feature.properties.BASENAME
                  : feature.properties.FULLNAME,
              );
              setHoverTooltip({
                exploratory,
                STUSPS: abbreviatedShortName,
                NAME: abbreviatedName,
                exploratoryDisplayValue: getMapDisplayValue({
                  exploratory,
                  value,
                }),
                label:
                  getNameForCode(exploratory) ||
                  getNameForScoreCode(exploratory),
                properties: feature.properties,
                state: feature.state,
                lng,
                lat,
                geo,
                detailedClick: handleClick,
                selectedFilters,
                geoContext: currentGeoContext,
              });
            } catch (e) {
              console.error("Error", e);
            }
          } else {
            setHoverTooltip(null);
            setHoveredId((prev) => {
              return { id: "", prevId: prev?.id };
            });
          }

          if (feature?.state.isBlocked) {
            setBlockedArea({ lng, lat });
          } else {
            setBlockedArea(null);
          }
        }}
        onMove={(event) => updateMapViewState(event)}
        //@ts-ignore
        projection="mercator"
        ref={(r) => {
          if (ref) ref.current = r;
        }}
        style={{ height: "100vh" }}
      >
        {map?.loaded && <MapData hoveredId={hoveredId} setLayers={setLayers} />}

        <>
          {keys(SourceMap).map((key) => {
            const sourceMap = SourceMap[key];

            return (
              <Source
                key={key}
                id={sourceMap.source}
                type="vector"
                tiles={[geoSettingsMap[key].source]}
                promoteId="GEOID"
              >
                {layers[sourceMap.source] &&
                  layers[sourceMap.source].map((layer) => (
                    <Layer key={layer.id} {...layer} />
                  ))}
              </Source>
            );
          })}
          <Source
            id="states-shape"
            type="geojson"
            data={{
              type: "FeatureCollection",
              features: stateCoordinates.map((stateData) => {
                return {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [stateData.lon, stateData.lat],
                  },
                  properties: {
                    NAME: stateData.name,
                    isFiltered: true,
                  },
                };
              }),
            }}
            promoteId="NAME"
          >
            {layers["states-shape"] &&
              layers["states-shape"].map((layer) => (
                <Layer key={layer.id} {...layer} />
              ))}
          </Source>
        </>

        {!isTabletOrMobile && (
          <>
            <div
              style={{
                bottom: "120px",
                margin: "10px",
                position: "absolute",
                right: "0",
              }}
              onClick={handleCenter}
            >
              <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
                <button
                  className="mapboxgl-ctrl-icon mapboxgl-ctrl-geolocate"
                  type="button"
                >
                  <span
                    className="mapboxgl-ctrl-icon"
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </div>
            <NavigationControl position="bottom-right" />
          </>
        )}
        {hoverTooltip && (
          <Popup
            css={{
              ".mapboxgl-popup-tip": {
                display: "none",
              },
              ".mapboxgl-popup": {
                paddingBottom: 50,
              },
            }}
            latitude={hoverTooltip.lat}
            longitude={hoverTooltip.lng}
            closeButton={false}
            closeOnClick={false}
            onClose={() => setHoverTooltip(null)}
            anchor="bottom"
            offset={[-30, -20] as Offset}
          >
            <HoverPin info={hoverTooltip} />
          </Popup>
        )}
        {blockedArea && (
          <Popup
            css={{
              ".mapboxgl-popup-tip": {
                display: "none",
              },
              ".mapboxgl-popup-content": {
                background: theme.colors.primary,
              },
            }}
            latitude={blockedArea.lat}
            longitude={blockedArea.lng}
            closeButton={false}
            closeOnClick={false}
            onClose={() => setHoverTooltip(null)}
            anchor="bottom"
            offset={[-30, -20] as Offset}
          >
            <div
              css={(theme) => ({
                padding: "5px 10px",
                color: theme.colors.white,
                fontWeight: theme.fontWeights.bold,
              })}
            >
              Upgrade to a Premium Plan to access this data, or switch to 1 of 8
              free datapoints in the datapoint selector to the left
            </div>
          </Popup>
        )}
      </BaseMap>
      <DataDrawer
        visible={Boolean(
          (Object.keys(graphData)?.length > 0 || graphContext) && graphView,
        )}
        onClose={() => {
          posthog.capture("Closing the graph window");
          const progress = { ...progressQuery };
          progress.graphView = false;
          setProgressQuery(progress);
          setShowGraphView(false);
          setGraphContext(null);
        }}
        customCss={{
          padding: "16px 0",
          overflow: "scroll",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <>
          {Boolean(
            (Object.keys(graphData)?.length > 0 || graphContext) && graphView,
          ) && (
            <TimeSeriesExploratory
              exploratory={exploratory}
              geo={geo}
              handleExploratoryChange={handleExploratoryChange}
              height={isTabletOrMobile ? null : 450}
              info={
                graphContext && Object.keys(graphContext)?.length > 0
                  ? graphContext
                  : graphData
              }
              width={isTabletOrMobile ? null : 1000}
            />
          )}
        </>
      </DataDrawer>

      <DataDrawer visible={tableView} onClose={() => setTableView(false)}>
        {ref && (
          <DataTable
            exploratory={exploratory}
            filteredStates={filteredStates}
            geo={geo}
            layer={LayerFillIds[geo]}
            mapData={currentData}
            ref={ref}
            selectedFilters={selectedFilters}
            tableView={tableView}
          />
        )}
      </DataDrawer>
    </div>
  );
}
