import { useTheme } from "@emotion/react";
import useFilters from "hooks/useFilters";
import useGeoSearch from "hooks/useGeoSearch";
import useMapData from "hooks/useMapData";
import useUser, { useUserData } from "hooks/useUser";
import { metroNamesAndGeoId } from "lib/constants";
import { MapContext } from "lib/context";
import { getCodesToFilter, getMapDisplayValue } from "lib/helpers";
import { checkNumberValidity } from "lib/helpers/common";
import { isExploratoryScore } from "lib/helpers/exploratoryHelpers";
import { getColorScale } from "lib/helpers/mapHelpers";
import { getScoreRangeLevel } from "lib/helpers/scoreHelpers";
import { hasAccessToPremiumArea } from "lib/helpers/userHelpers";
import { metroList } from "lib/options/metroList";
import {
  clearPreviousFeaturesByLayer,
  createGeoId,
  featureMeetsCriteriaState,
  getLayers,
  repaintHistoricMap,
  repaintMap,
} from "lib/mapSettings";
import { metroMap } from "lib/options/metroMap";
import { stateZipMap } from "lib/options/stateZipMap";
import scoreDatapointsMap from "lib/scoreBoard/scoreDatapointsMap";
import { zipMetro } from "lib/zipMetro";
import { keys, mapValues } from "lodash";
import { Expression } from "mapbox-gl";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LayerProps, useMap } from "react-map-gl";
import { Geo } from "types/MapContext";
import { FeatureState, SourceMap } from "types/Mapbox";
import { CurrentDataPoints, Datapoints } from "types/cube";
import { PlanModalContext } from "components/provider/PlanModalProvider";

interface Props {
  hoveredId: any;
  setLayers: Dispatch<SetStateAction<Record<string, LayerProps[]>>>;
}

const MapData = ({ hoveredId, setLayers }: Props) => {
  const theme = useTheme();
  const { current: map } = useMap();
  const user = useUserData();

  const { setScale } = useContext(MapContext);
  const {
    geo,
    selectedFeature,
    filteredMetros,
    stateList,
    searchZipsViaState,
  } = useGeoSearch();
  const { exploratory, selectedFilters } = useFilters();
  const { currentData } = useMapData();
  const {
    showPlanModal: isPlanModalOpen,
    setShowPlanModal,
    setPlanPopupTrigger,
  } = useContext(PlanModalContext);
  const { isLoading: isUserDetailsLoading } = useUser();

  const [featureStates, setFeatureStates] = useState<FeatureState[]>([]);

  useEffect(() => {
    initLayers();
  }, [currentData, exploratory, selectedFeature]);

  const isScore = useMemo(() => {
    return keys(scoreDatapointsMap).some((key) => key === (exploratory as any));
  }, [exploratory]);

  useEffect(() => {
    if (!!featureStates.length && map) {
      const isScore = isExploratoryScore(exploratory);
      const isHomeValueFiveYrGrowth =
        exploratory === Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE;
      if (
        [
          Datapoints.HOME_VALUE_GROWTH_RATE,
          Datapoints.HOME_VALUE_FIVE_YEAR_GROWTH_RATE,
          Datapoints.OVER_UNDER_VALUED_PERCENTAGE,
          Datapoints.MOM_HOME_VALUE_GROWTH,
          Datapoints.YOY_INVENTORY_CHANGE_PERCENTAGE,
          Datapoints.MOM_ACTIVE_INVENTORY_CHANGE_PERCENTAGE,
          Datapoints.PER_CHANGE_HOME_VALUE_JUNE,
          Datapoints.PERCENT_CRASH_GREAT_RECESSION,
          Datapoints.FOR_SALE_INVENTORY_V_AVG,
        ].includes(exploratory) ||
        isScore
      ) {
        repaintHistoricMap({
          map: map.getMap(),
          geo,
          theme,
          setScale,
          exploratory,
          featureStates,
          midPoint: isScore ? 50 : isHomeValueFiveYrGrowth ? 30 : 0,
        });
      } else {
        repaintMap({
          map: map.getMap(),
          geo,
          theme,
          setScale,
          exploratory,
          featureStates,
        });
      }
    }
  }, [featureStates]);

  useEffect(() => {
    if (!currentData.length) return;

    executeOnLoad(() => {
      const reset = !selectedFilters.some((filter) => !!filter.val.length);
      handleFilters(currentData, reset);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  useEffect(() => {
    if (!(map && currentData && hoveredId?.id)) return;
    currentData.forEach((row) => {
      map.setFeatureState(
        {
          source: SourceMap[geo].source,
          sourceLayer: SourceMap[geo].sourceLayer,
          id: createGeoId(row, geo),
        },
        {
          isHovered: createGeoId(row, geo) === hoveredId?.id,
        },
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredId]);

  useEffect(() => {
    if (geo === Geo.ZIP && map) {
      executeOnLoad(() => {
        clearPreviousFeaturesByLayer(
          [Geo.STATE, Geo.METRO, Geo.COUNTY, Geo.ZIP],
          map,
        );
      });
    }
  }, [filteredMetros[0], geo, searchZipsViaState]);

  function handleFilters(
    datapointValues: CurrentDataPoints[],
    reset?: boolean,
  ) {
    if (!map) return;

    const codesToFilter = reset ? [] : getCodesToFilter(selectedFilters);

    if (!codesToFilter.length) {
      codesToFilter.push({
        filterCode: "home_value",
        selectedFilter: {
          label: "Home Value",
          val: [],
        },
      });
    }

    const statesToSet = codesToFilter.reduce(
      (acc, { filterCode, selectedFilter }) => {
        datapointValues.forEach((row) => {
          const GEOID = createGeoId(row, geo);

          if (!(GEOID in acc)) {
            acc[GEOID] = {
              featureState: {
                isFiltered: true,
                isLabelHidden: false,
              },
            };
          }

          const filteredExploratoryValue = row[filterCode];
          const getStateCode = (value: any) => {
            if (
              geo === Geo.METRO &&
              selectedFilter.label === "state_code" &&
              !filteredExploratoryValue
            ) {
              const tep = metroList.find(
                (metro) => metro.value.toString() === GEOID,
              )?.["FIPS State Code"];
              return tep;
            } else {
              return value;
            }
          };

          const isFeatureCriteriaMet = featureMeetsCriteriaState(
            selectedFilter,
            getStateCode(filteredExploratoryValue),
          );

          const isFiltered =
            acc[GEOID]?.featureState?.isFiltered &&
            ((checkNumberValidity(row[exploratory]) &&
              checkNumberValidity(filteredExploratoryValue)) ||
              selectedFilter.label === "state_code") &&
            isFeatureCriteriaMet;

          acc[GEOID] = {
            id: GEOID,
            geo_name: row.geo_name,
            featureState: {
              isFiltered,
              isLabelHidden:
                acc[GEOID]?.featureState.isLabelHidden || !isFeatureCriteriaMet,
            },
          };
        });

        return acc;
      },
      {},
    );

    featureStates.map((fs) => {
      try {
        if (!(fs.GEOID in statesToSet)) {
          return fs;
        }

        const state = statesToSet[fs.GEOID];

        map.setFeatureState(
          {
            source: SourceMap[geo].source,
            sourceLayer: SourceMap[geo].sourceLayer,
            id: state.id,
          },
          state.featureState,
        );
        if (geo === Geo.STATE) {
          map.setFeatureState(
            {
              source: "states-shape",
              id: state.geo_name,
            },
            state.featureState,
          );
        } else {
          map.setFeatureState(
            {
              source: SourceMap[geo].source,
              sourceLayer: `${SourceMap[geo].sourceLayer}/label`,
              id: state.id,
            },
            state.featureState,
          );
        }

        return { ...fs, ...state.featureState };
      } catch (error) {
        return fs;
      }
    });
  }

  function buildLayers(
    datapointValues: CurrentDataPoints[],
  ): [Record<string, LayerProps[]>, FeatureState[]] {
    if (!map) return [{}, []];

    const matchExpression = [
      "match",
      ["get", SourceMap[geo].nameProperty],
    ] as Expression;

    if (geo === Geo.ZIP && searchZipsViaState === "All States")
      addMetroLayers();

    const featureStates = setFeatureStateByData(
      datapointValues,
      matchExpression,
    );

    const scale = getColorScale(featureStates, exploratory);

    const layers = getLayers({
      geoLayerPrefix: SourceMap[geo].source,
      sourceName: SourceMap[geo].sourceLayer,
      geo,
      exploratory,
      scale,
      matchExpression,
      theme,
    });

    if (
      datapointValues.length &&
      selectedFilters.some((filter) => !!filter.val.length)
    ) {
      handleFilters(datapointValues);
    }

    return [layers, featureStates];
  }

  function initLayers() {
    if (!(map && !!(currentData.length && currentData[0].geo === geo))) return;
    executeOnLoad(() => {
      const [layers, features] = buildLayers(currentData);
      setLayers(layers);
      setFeatureStates(features);
    });
  }

  function getStateFromRow(row: CurrentDataPoints): string {
    if (row.geo === Geo.STATE) {
      return row.geo_code;
    }
    if (row.geo === Geo.METRO) {
      return (
        metroMap.find(
          (metro) => metro["CBSA Code"] === parseInt(row.geo_code),
        )?.["FIPS State Code"] + ""
      );
    }
    if (row.geo === Geo.COUNTY) {
      return row.state_code;
    }
    if (row.geo === Geo.ZIP) {
      for (let state in stateZipMap) {
        if (stateZipMap[state].some((zip) => zip === parseInt(row.geo_code)))
          return stateList.find((item) => item.label === state)?.value + "";
      }
    }

    return "";
  }

  function addMetroLayers() {
    if (!map) return;

    metroNamesAndGeoId.forEach((metroData) => {
      const metroGeoId = metroData[1];
      map.setFeatureState(
        {
          source: "metros",
          sourceLayer: "CBSA",
          id: metroGeoId,
        },
        {
          isFiltered: true,
        },
      );
      map.setFeatureState(
        {
          source: "metros",
          sourceLayer: "CBSA/label",
          id: metroGeoId,
        },
        {
          isFiltered: true,
        },
      );
    });
  }

  function updateMetroLayer(GEOID: string) {
    if (!map) return;

    const matchedMetro = zipMetro[parseInt(GEOID)];
    const isFilteredByMetro = !filteredMetros?.length
      ? filteredMetros?.some((metro) => {
          return metro.value == matchedMetro?.toString();
        })
      : false;

    const cbsaPromoteId =
      matchedMetro || Math.floor(Math.random() * 1000000000);
    map.setFeatureState(
      {
        source: SourceMap[Geo.METRO].source,
        sourceLayer: SourceMap[Geo.METRO].sourceLayer,
        id: cbsaPromoteId,
      },
      {
        isFiltered: isFilteredByMetro,
      },
    );
    map.setFeatureState(
      {
        source: SourceMap[geo].source,
        sourceLayer: `${SourceMap[geo].sourceLayer}/label`,
        id: cbsaPromoteId,
      },
      {
        isFiltered: isFilteredByMetro,
      },
    );
  }

  function executeOnLoad(fn: () => void) {
    if (!map) return;

    window.requestAnimationFrame(fn);

    // if (!map.loaded()) {
    //   const onIdle = () => {
    //     map.off("idle", onIdle);
    //     fn();
    //   };
    //   map.on("idle", onIdle);
    // } else {
    //   fn();
    // }
  }

  function cleanRowForBasic(row: CurrentDataPoints) {
    return mapValues(row, (v, k) =>
      ["geo_name", "geo_code", "state_code"].includes(k) ? v : null,
    );
  }

  function setFeatureStateByData(
    datapointValues: CurrentDataPoints[],
    matchExpression: Expression,
  ) {
    if (!map) return [];

    const featureStates: FeatureState[] = [];

    datapointValues.forEach((row) => {
      try {
        const GEOID = createGeoId(row, geo);
        const state = getStateFromRow(row);
        const exploratoryValue = row[exploratory];
        const isSearched = selectedFeature
          ? row.geo_name === selectedFeature?.text
          : false;

        const isAreaFromPremiumState = hasAccessToPremiumArea(
          user,
          row,
          exploratory,
          geo,
        );

        if (
          !isPlanModalOpen &&
          !isAreaFromPremiumState &&
          !isUserDetailsLoading &&
          !user.isPremiumOrBasic
        ) {
          setPlanPopupTrigger("Land on premium datapoint");
          setShowPlanModal(true);
        }
        if (geo === Geo.ZIP) updateMetroLayer(GEOID);

        const cleanedRow = (
          isAreaFromPremiumState ? row : cleanRowForBasic(row)
        ) as CurrentDataPoints;

        const isFiltered = isAreaFromPremiumState
          ? checkNumberValidity(exploratoryValue)
          : false;

        const featureState: FeatureState = {
          ...cleanedRow,
          state,
          STATE: state,
          GEOID,
          isFiltered,
          isSearched,
          isInvalid: !isFiltered,
          isBlocked: !isAreaFromPremiumState,
          isLabelHidden: false,
        };

        map.setFeatureState(
          {
            source: SourceMap[geo].source,
            sourceLayer: SourceMap[geo].sourceLayer,
            id: GEOID,
          },
          featureState,
        );

        if (geo === Geo.STATE) {
          map.setFeatureState(
            {
              source: "states-shape",
              id: row?.geo_name,
            },
            {
              ...cleanedRow,
              isFiltered,
            },
          );
        } else {
          map.setFeatureState(
            {
              source: SourceMap[geo].source,
              sourceLayer: `${SourceMap[geo].sourceLayer}/label`,
              id: GEOID,
            },
            {
              isFiltered,
            },
          );
        }

        featureStates.push(featureState);

        const expression = [
          "concat",
          ["get", geo === Geo.STATE ? "NAME" : "BASENAME"],
        ];

        if (isFiltered) {
          expression.push(
            "\n",
            getMapDisplayValue({ exploratory, value: exploratoryValue }),
          );

          if (isScore) {
            const scoreLevel = getScoreRangeLevel(
              exploratoryValue / 100,
              exploratory as any,
            );

            const arrow =
              scoreLevel === 0
                ? "\u2198"
                : scoreLevel === 1
                ? "\u2192"
                : "\u2197";
            expression.push("\n", arrow);
          }
        }

        matchExpression.push(
          geo === Geo.STATE ? row?.geo_name : GEOID,
          expression,
        );
      } catch (error) {}
    });

    if (matchExpression.length === 2) {
      matchExpression.push("", "");
    }

    matchExpression.push("");

    return featureStates;
  }

  return <></>;
};

export default MapData;
