import { Button, Checkbox, Collapse, Modal } from "antd";
import { Flex } from "components/Flex";
import PenalChevron from "components/exploratory/PenalChevron";
import ExploratoryTitle from "components/exploratory/ExploratoryTitle";
import PremiumIcon from "components/icons/PremiumIcon";
import useGeoExploratory from "hooks/useGeoExploratory";
import { exploratoryMap } from "lib/options/exploratoryMap";
import { exploratoryGroupMap } from "lib/options/exploratory";
import { useEffect, useState } from "react";
import "tippy.js/dist/tippy.css";
import useFilters from "hooks/useFilters";

const { Panel } = Collapse;

export const AddFiltersModal = ({
  geo,
  modalIsOpen,
  setModalIsOpen,
  lastFilterAsExploratory,
  setExploratory,
  expoFilters,
}) => {
  const { groupedExploratories } = useGeoExploratory(geo);
  const { selectedFilters, setSelectedFilters, setSelectedPreset } =
    useFilters();

  const [activeTabs, setActiveTabs] = useState<string | string[]>(
    Object.values(exploratoryGroupMap),
  );
  const [selectedFilterLabels, setSelectedFilterLabels] = useState<string[]>(
    [],
  );

  useEffect(() => {
    if (!!expoFilters) {
      setSelectedFilterLabels(expoFilters.map((filter) => filter.label));
    }
  }, [expoFilters]);

  const addFilters = () => {
    const filters = selectedFilterLabels.map((label) => {
      const existing = selectedFilters.find((fil) => fil.label === label);
      return { label, val: !!existing ? existing.val : [] };
    });

    setSelectedPreset("");
    setSelectedFilters(filters);
    setModalIsOpen(false);

    if (lastFilterAsExploratory) {
      if (!!selectedFilterLabels.length) {
        for (const key in exploratoryMap) {
          if (exploratoryMap[key].filterName === selectedFilterLabels.at(-1)) {
            setExploratory(exploratoryMap[key].code);
          }
        }
      }
    }
  };

  const onCheckboxClick = (option) => {
    if (selectedFilterLabels.includes(option.label)) {
      setSelectedFilterLabels((prev) =>
        prev.filter((label) => label !== option.label),
      );
    } else {
      setSelectedFilterLabels((prev) => [...prev, option.label]);
    }
  };

  return (
    <Modal
      title="Add Filter"
      open={modalIsOpen}
      onCancel={() => setModalIsOpen(false)}
      zIndex={1500}
      footer={[
        <Button
          key="add"
          type="primary"
          css={(theme) => ({
            width: "100%",
            borderRadius: theme.radii.smaller,
          })}
          onClick={addFilters}
        >
          Add Filters
        </Button>,
      ]}
      css={(theme) => ({
        borderRadius: theme.radii.smaller,
        overflow: "hidden",

        ".ant-modal-title": {
          fontSize: theme.fontSizes.large,
          fontWeight: theme.fontWeights.heading,
          color: theme.colors.text2,
        },

        ".ant-modal-close-icon": {
          padding: theme.padding.default,
          borderRadius: theme.radii.smaller,
          backgroundColor: theme.colors.lightBg,
        },

        ".ant-modal-body": {
          display: "flex",
          gap: theme.gap[10],
        },

        ".ant-collapse": {
          flex: 1,
        },

        ".ant-collapse-header-text, .ant-collapse-extra": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
        },

        ".ant-collapse-header": {
          padding: `${theme.padding.default}px 0 !important`,
        },

        ".ant-collapse-content-box": {
          padding: "0",
        },

        ".ant-checkbox-wrapper+.ant-checkbox-wrapper": {
          marginLeft: 0,
        },

        ".ant-checkbox-wrapper": {
          display: "flex",
        },

        ".ant-checkbox-wrapper span:nth-of-type(2)": {
          flex: 1,
          paddingRight: 0,
        },
      })}
    >
      <Collapse
        ghost
        activeKey={activeTabs}
        onChange={(e) => {
          setActiveTabs(e);
        }}
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          maxHeight: 400,
          overflow: "scroll",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        })}
      >
        {groupedExploratories?.slice(0, 2)?.map((group) => {
          if (!group.options?.length) return null;

          return (
            <Panel
              header={
                group.label === "Premium" ? (
                  <Flex
                    align="center"
                    justify="flex-start"
                    css={(theme) => ({ gap: theme.gap[1] })}
                  >
                    <span>{group.label}</span>
                    <PremiumIcon />
                  </Flex>
                ) : (
                  group.label
                )
              }
              key={group.label}
              showArrow={false}
              extra={
                <PenalChevron
                  active={(activeTabs as string[]).some(
                    (tab) => tab === group.label,
                  )}
                />
              }
            >
              {group.options?.map((option) => {
                if (
                  option.label === "Overvalued %" &&
                  (geo === "county" || geo === "zip")
                ) {
                  return null;
                }
                return (
                  <Checkbox
                    key={option.value}
                    checked={selectedFilterLabels.includes(option.label)}
                    onClick={() => onCheckboxClick(option)}
                  >
                    <ExploratoryTitle
                      title={option.label}
                      value={option.value}
                      isPremium={option.premium}
                      ytVideoCode={option.ytVideoCode}
                      option={option}
                    />
                  </Checkbox>
                );
              })}
            </Panel>
          );
        })}
      </Collapse>

      <Collapse
        ghost
        activeKey={activeTabs}
        onChange={(e) => {
          setActiveTabs(e);
        }}
        css={(theme) => ({
          display: "flex",
          flexDirection: "column",
          maxHeight: 400,
          overflow: "scroll",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        })}
      >
        {groupedExploratories?.slice(2)?.map((group) => {
          if (!group.options?.length) return null;

          return (
            <Panel
              header={group.label}
              key={group.label}
              showArrow={false}
              extra={
                <PenalChevron
                  active={(activeTabs as string[]).some(
                    (tab) => tab === group.label,
                  )}
                />
              }
            >
              {group.options?.map((option) => {
                if (
                  option.label === "Overvalued %" &&
                  (geo === "county" || geo === "zip")
                ) {
                  return null;
                }
                return (
                  <Checkbox
                    key={option.value}
                    checked={selectedFilterLabels.includes(option.label)}
                    onClick={() => onCheckboxClick(option)}
                  >
                    <ExploratoryTitle
                      title={option.label}
                      value={option.value}
                      isPremium={option.premium}
                      ytVideoCode={option.ytVideoCode}
                      option={option}
                    />
                  </Checkbox>
                );
              })}
            </Panel>
          );
        })}
      </Collapse>
    </Modal>
  );
};
